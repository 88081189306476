import Navigo from 'navigo';

const nav = document.getElementById('page-nav');
const backBtn = document.getElementById('page-back-btn');

class Router {
  init() {
    const r = new Navigo('/');
    const main = document.getElementsByTagName('main')[0];

    r.on('/', function(){
      main.dataset.page = 'home';

      nav.classList.remove('back-active');
      backBtn.setAttribute('tabindex', '-1'); 
    });

    r.on('/portfolio/:id', function(match){
      const active = document.querySelector('#project-page section.active');
      if (active) active.classList.remove('active');

      const newActive = document.querySelector(`[data-entry=${match.data.id}]`);
      if (newActive) newActive.classList.add('active');
      
      main.dataset.page = 'project';

      nav.classList.add('back-active');
      backBtn.removeAttribute('tabindex');
    });

    r.resolve();

    return r;
  }
}

export const router = new Router();
